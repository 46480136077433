import React, { Component } from 'react';
import moment from 'moment';
import ResourceList from './ResourceList';
import AddResource from './AddResource';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import { properties } from '../properties.js';
import { NavLink } from 'react-router-dom'

class Stack extends Component {

  prevState = null;

  constructor(props) {
    super(props);

    this.isAdding= props.isAdding || false;
    this.isEditing= props.isEditing || false;

    this.state= {
      stack: props,
      actionStarted: false,
      launchSuccessful: false,
      poweroffSuccessful: false,
      isAdding: this.isAdding,
      isEditing: this.isEditing,
      isDeleted: false,
      error: null,
    };
  }

  handleAction = (fct) => (event) => {
    console.log(fct);
    Auth.currentSession().then(session => {
      console.log('session loaded on first try')
      fct(session.idToken.jwtToken);
    }).catch(e => {
      console.log('error while retrieving session, try again');
      console.log(e);
      Auth.currentSession().then(session => {
          console.log('session loaded on second try')
          fct(session.idToken.jwtToken);
        }).catch(e => {
          console.log('error while retrieving session, give up');
          console.log(e);
        });
    });
  }

  status(idToken)
  {
    var stackName = this.state.stack.name;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;
    // launch the stack

    console.log('url : '+properties.baseApiUrl+'/bringup-api/status/'+stackName);

    this.setState({status_fetching : 'Fetching status...'});
    axios.get(properties.baseApiUrl+'/bringup-api/status/'+stackName)
      .then(resp => {
        var state_html = '<ul>';
        for (var key in resp.data) {
          state_html += '<li><ul>';
          for (var subkey in resp.data[key]) {
            state_html += '<li>'+subkey+' : '+resp.data[key][subkey]+'</li>';
          }
          state_html += '</ul></li>';
        }
        state_html += '</ul>';
        this.setState({status : state_html});
        this.setState({status_fetching : 'Fetching status done.'});
      })
      .catch(e => {
        this.setState({status_fetching : 'Fetching status failed.'});
        this.setState({error : e});
      });
  }

  launch(idToken)
  {
    this.setState({actionStarted : true});
    var stackName = this.state.stack.name;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;
    // launch the stack

    var body = {
      launch: stackName,
      source: 'Launch.js'
    };
    console.log('launching stack ' + stackName);
    console.log('url : '+properties.baseApiUrl+'/bringup-api/launch/'+stackName);
    console.log('body : '+JSON.stringify(body));
    axios.put(properties.baseApiUrl+'/bringup-api/launch/'+stackName, body)
      .then(resp => {
        this.setState({launchSuccessful : true});
      })
      .catch(e => {
        this.setState({error : e.response.data});
      });
  }

  powerOff(idToken)
  {
    this.setState({actionStarted : true});
    var stackName = this.state.stack.name;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;
    // poweroff the stack

    axios.put(properties.baseApiUrl+'/bringup-api/poweroff/'+stackName, { poweroff: stackName, source: 'Stack.js' })
      .then(resp => {
        this.setState({poweroffSuccessful : true});
      })
      .catch(e => {
        this.setState({error : e.response.data});
      });
  }

  update(idToken)
  {
    var stackName = this.state.stack.name;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;
    // update the stack

    axios.put(properties.baseApiUrl+'/bringup-api/'+stackName, this.state.stack)
      .then(resp => {
        this.setState({actionStarted : false, isEditing : false});
      })
      .catch(e => {
        this.setState({actionStarted : false, isEditing : false, error : e.response.data});
      });
  }

  remove(idToken)
  {
    var stackName = this.state.stack.name;

    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;
    // delete the stack
    axios.delete(properties.baseApiUrl+'/bringup-api/'+stackName)
      .then(resp => {
        this.setState({isDeleted: true});
      })
      .catch(e => {
        this.setState({actionStarted : false, isDeleting : false, error : e.response.data});
      });
  }

  handleStartEdit = (event) => {
    // make deep copy as previous state
    this.prevState = JSON.parse(JSON.stringify(this.state.stack));
    this.setState({actionStarted: true, isEditing: true} );
  }

  handleStartDelete = (event) => {
    this.setState({actionStarted: true, isDeleting: true} );
  }

  handleUpdateChange = event => {
    var item = {...this.state.stack};
    item[event.target.name] = event.target.value;
    this.setState({ stack : item });
  }

  handleCheckboxChange = event => {
    var item = {...this.state.stack};
    item[event.target.name] = event.target.checked;
    this.setState({ stack : item });
  }

  handleDeleteResource = resource => event => {
    var item = {...this.state.stack};

    var index = item.stackItems.indexOf(resource);
    if (index > -1){
      item.stackItems.splice(index, 1);
      this.setState({ stack : item });
    }
  }

  // move the selected resource up or down in the list
  handleMoveResource = (index, direction) => event =>{
    var item = {...this.state.stack};
    var resource = item.stackItems[index];
    console.log(resource);
    console.log(item.stackItems);

    if (index > -1){
        item.stackItems.splice(index, 1);
        item.stackItems.splice(index - direction, 0, resource);
        this.setState({ stack : item });
    }
  }

  handleAddResource = (resource) => (event) => {
    var item = {...this.state.stack};
    if (!item.stackItems) {
      item.stackItems = [];
    }
    item.stackItems.push(resource);
    this.setState({ stack : item });
  }

  onCancelEdit = (event) => {
    this.setState({isEditing : false, actionStarted : false, stack: this.prevState});
  }

  onCancelDelete = (event) => {
    this.setState({isDeleting : false, actionStarted : false});
  }

  isValid(){
    var valid = true;
    if (
      this.state.stack.name.trim() === ""
    ) {
      valid = false;
    }
    if (
      this.state.stack.estimatedMinutesToSpinUp === undefined ||
      this.state.stack.estimatedMinutesToSpinUp === ""
    ) {
      valid = false;
    }
    if (
      this.state.stack.stackItems === undefined ||
      this.state.stack.stackItems.length === 0
    ) {
      valid = false;
    }
    if (
      this.state.stack.schedule && (
        this.state.stack.scheduleDate.trim() === "" ||
        this.state.stack.scheduleTime.trim() === "" ||
        this.state.stack.scheduleFrequency.trim() === ""
      )
    ) {
      valid = false;
    }
    return valid;
  }

  render(){
    var launchTime = this.state.stack.lastLaunch && moment.unix(this.state.stack.lastLaunch/1000);
    return (
      <tr hidden={this.state.isDeleted}>
        {
          !this.state.isEditing &&
          <>
            <td>
              <div>
                <label>Name :</label>{this.state.stack.name}
                <br></br>
                { this.state.stack.estimatedMinutesToSpinUp ?
                    <>
                      [Approximately {this.state.stack.estimatedMinutesToSpinUp} minutes to launch.]
                    </> :
                    <>
                      [Incomplete stack - please edit and add missing details!]
                    </>
                }
                <br></br>
                  { 
                    this.state.stack.schedule && 
                    <>
                    <label>Schedule Date :</label>{this.state.stack.scheduleDate}
                    <br></br>
                    <label>Schedule Time :</label>{this.state.stack.scheduleTime}
                    <br></br>
                    <label>Schedule Frequency :</label>{this.state.stack.scheduleFrequency}
                    <br></br>
                    </>
                  }
                  {
                    this.state.stack.stopResourcesAfter &&
                    <>
                    <label>Stop resources after:</label>{this.state.stack.stopResourcesAfter}
                    </>
                  }
                <br></br>
                <NavLink to={'/launch/'+this.state.stack.name} className='nav-link'>Share link</NavLink>
              </div>
            </td>
            <td>
              <ResourceList resources={this.state.stack.stackItems} isEditing={this.state.isEditing} onDelete={this.handleDeleteResource}/>
            </td>
          </>
        }
        {
          this.state.isEditing &&
          <>
            <td>
              <div>
                { 
                  this.state.isAdding &&
                  <input type="text" value={this.state.stack.name} onChange={this.handleUpdateChange} className="form-control" name="name" placeholder="Name for your test stack"/>
                }
                { 
                  !this.state.isAdding &&
                  <>
                    <label>Name :</label>{this.state.stack.name}
                  </>
                }
                <br></br>
                <input type="text" value={this.state.stack.estimatedMinutesToSpinUp} onChange={this.handleUpdateChange}
                className="form-control" name="estimatedMinutesToSpinUp" placeholder="Estimated nr of minutes to spin up. Example '5'" />
              </div>
              <div>
                <label>Schedule</label>
                {' '}
                <input type="checkbox" checked={this.state.stack.schedule} name="schedule" onChange={this.handleCheckboxChange}
                  placeholder="Should this bringup set be scheduled."/>
                {' '}
                { 
                  this.state.stack.schedule && 
                  <div> 
                    <label>Schedule Date :</label><input type="text" value={this.state.stack.scheduleDate} onChange={this.handleUpdateChange}
                      className="form-control" name="scheduleDate" placeholder="(YYYY-MM-DD)"/>
                    <label>Schedule Time :</label><input type="text" value={this.state.stack.scheduleTime} onChange={this.handleUpdateChange}
                      className="form-control" name="scheduleTime" placeholder="(HH:MM)"/>
                    <div className="radio">
                      <label>
                        <input type="radio" value="once" checked={this.state.stack.scheduleFrequency === "once" || this.state.stack.scheduleFrequency === ""} onChange={this.handleUpdateChange} name="scheduleFrequency"/>
                        Once
                      </label>
                      {' '}
                      <label>
                        <input type="radio" value="daily" checked={this.state.stack.scheduleFrequency === "daily"} onChange={this.handleUpdateChange} name="scheduleFrequency"/>
                        Daily
                      </label>
                      {' '}
                      <label>
                        <input type="radio" value="weekdays" checked={this.state.stack.scheduleFrequency === "weekdays"} onChange={this.handleUpdateChange} name="scheduleFrequency"/>
                        Weekdays
                      </label>
                      {' '}
                      <label>
                        <input type="radio" value="weekends" checked={this.state.stack.scheduleFrequency === "weekends"} onChange={this.handleUpdateChange} name="scheduleFrequency"/>
                        Weekends
                      </label>
                      {' '}
                      <label>
                        <input type="radio" value="weekly" checked={this.state.stack.scheduleFrequency === "weekly"} onChange={this.handleUpdateChange} name="scheduleFrequency"/>
                        Weekly
                      </label>
                      {' '}
                      <label>
                        <input type="radio" value="monthly" checked={this.state.stack.scheduleFrequency === "monthly"} onChange={this.handleUpdateChange} name="scheduleFrequency"/>
                        Monthly
                      </label>
                    </div>
                  </div>
                }
                <div>
                  <label>Stop resources after:</label><input type="text" value={this.state.stack.stopResourcesAfter} onChange={this.handleUpdateChange}
                      className="form-control" name="stopResourcesAfter" placeholder="(HH:MM)"/>
                </div>
              </div>
            </td>
            <td>
              <ResourceList resources={this.state.stack.stackItems} isEditing={this.state.isEditing} onDelete={this.handleDeleteResource}  onMoveResourceUp={this.handleMoveResource} onMoveResourceDown={this.handleMoveResource}/>
              <AddResource onAdd={this.handleAddResource}/>
            </td>
          </>
        }
        <td>
          { 
            (!this.state.isEditing && launchTime) &&
            <>
            <span title={launchTime.format()}>{launchTime.calendar()}</span>
            </>
          }
          {
            (!this.state.isEditing) &&
            <div>
              <button type="button" className="btn btn-default" onClick={this.handleAction(this.status.bind(this))} title="Status">
                Status
              </button>
              <span> {this.state.status_fetching}</span>
            </div>
          }
          {
            (!this.state.isEditing && this.state.status) && 
            <div className="alert alert-info box-msg" role="alert" dangerouslySetInnerHTML={{ __html: this.state.status }}/>
          }
        </td>
        <td>
          {
            (!this.state.isEditing && !this.state.actionStarted) && 
            <div>
              {
                !this.props.isAdminMode && 
                <div>
                  <button type="button" className="btn btn-default" onClick={this.handleAction(this.launch.bind(this))} title="Launch">
                    <span className="   glyphicon glyphicon-play-circle"></span>
                  </button>
                  <button type="button" className="btn btn-default" onClick={this.handleAction(this.powerOff.bind(this))} title="Power Off">
                    <span className="   glyphicon glyphicon-stop"></span>
                  </button>
                </div>
              }
              { 
                this.props.isAdminMode &&
                <div>
                  <button type="button" className="btn btn-default" onClick={this.handleStartEdit} title="Edit">
                    <span className="glyphicon glyphicon-pencil"></span>
                  </button>
                  <button type="button" className="btn btn-default" onClick={this.handleStartDelete} title='Delete'>
                    <span className="glyphicon glyphicon-trash"></span>
                  </button>
                  <button type="button" className="btn btn-default" onClick={this.props.onDuplicate(this.state.stack)} title='Copy'>
                    <span className="glyphicon  glyphicon glyphicon-duplicate"></span>
                  </button>
                </div>
              }
            </div>
          }
          {
            this.state.isEditing && 
            <div>
            <button type="button" className="btn btn-default" onClick={this.handleAction(this.update.bind(this))} title="Update" disabled={!this.isValid()}>
              <span className="   glyphicon glyphicon-ok"></span>
            </button>
              { 
                !this.state.isAdding &&
                <button type="button" className="btn btn-default" onClick={this.onCancelEdit} title="Cancel">
                  <span className="   glyphicon glyphicon-remove"></span>
                </button> 
              }
              { 
                this.state.isAdding &&
                <button type="button" className="btn btn-default" onClick={this.props.onCancel} title='Cancel'>
                  <span className="glyphicon glyphicon-remove"></span>
                </button>
              }
            </div>
          }
          {
            this.state.isDeleting && 
            <div>
              <button type="button" className="btn btn-default" onClick={this.handleAction(this.remove.bind(this))} title="Confirm">
                <span className="glyphicon glyphicon-ok"></span>
              </button>
              <button type="button" className="btn btn-default" onClick={this.onCancelDelete} title='Cancel'>
                <span className="glyphicon glyphicon-remove"></span>
              </button>
            </div>
          }
          {
            this.state.launchSuccessful && 
            <div className="alert alert-success box-msg" role="alert">
              <strong>Stack launching</strong>, Expected wait-time :
              {this.props.estimatedMinutesToSpinUp ? <>{this.props.estimatedMinutesToSpinUp} minutes</> : <><i>unknown</i></>}
            </div>
          }
          {
            this.state.poweroffSuccessful && 
            <div className="alert alert-success box-msg" role="alert">
              <strong>Stack powering off</strong>
            </div>
          }
          {
            this.state.error && 
            <>
            {/* <div className="alert alert-danger box-msg" role="alert">Failed : {this.state.error}</div> */}
            <div className="alert alert-danger box-msg" role="alert" dangerouslySetInnerHTML={{ __html: this.state.error }}/>
            </>
          }
        </td>
      </tr>
    );
  }
}

export default Stack;
