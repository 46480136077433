
                    export const properties = {
                      baseApiUrl: 'https://f8qa0vzfx7.execute-api.eu-west-1.amazonaws.com/Prod',
                      authDomain: 'vrt-core.auth.eu-west-1.amazoncognito.com',
                      redirectUrl: 'https://bringup-sandbox.sandbox.a51.be',
                      region: 'eu-west-1',
                      userPoolId: 'eu-west-1_QBt6fNXsi',
                      userPoolWebClientId: '7uk0mnnru2skl65knponhrelg9',
                    };
                    
